import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import * as CryptoJS from "crypto-js";
import { AzureApiService } from "./azure-services/azure-api.service";
@Injectable({
  providedIn: "root",
})
export class ServiceService {
  // public adminURL = "http://iniyasdev.ukwest.cloudapp.azure.com:3000";
  // public adminURLAPI = "http://iniyasdev.ukwest.cloudapp.azure.com:3000/api";
  public CustomerURL = this.AzureApiService.configs.ApiEndpoint + "asset/";
  public loindetialsadmin;
  public CarServiceType = "Airport Transfer";
  public ParkingServiceType = "Airport Parking";
  public CookiesExpiryDate = 1;
  public StripePublicKeyStore = this.AzureApiService.configs.StripePublicKey;
  public StripePublicKeyStoreT = this.AzureApiService.configs.Trinqoo_StripePublicKey;
  public StripePublicKeyStoreV = this.AzureApiService.configs.Luxmi_StripePublicKey;
  public passAdminDetailsLocalStorage;
  constructor(
    public AzureApiService: AzureApiService,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    var getExpriyDateAdmin = localStorage.getItem("getAdminExpriyDate");
    var getExpriyDateAdminParsedData = JSON.parse(getExpriyDateAdmin);
    if (
      getExpriyDateAdminParsedData &&
      localStorage.getItem("adminLogindetails")
    ) {
      var GetStorageDate = new Date(getExpriyDateAdminParsedData);
      GetStorageDate.setDate(GetStorageDate.getDate() + 1);
      if (GetStorageDate > new Date()) {
        var encryptInfoAdmin = localStorage.getItem("adminLogindetails");
        var decryptAdminData = CryptoJS.AES.decrypt(
          decodeURIComponent(encryptInfoAdmin),
          "Iniyas@123"
        );
        var decryptedInfoAdmin = JSON.parse(
          decryptAdminData.toString(CryptoJS.enc.Utf8)
        );
        this.passAdminDetailsLocalStorage = JSON.stringify(decryptedInfoAdmin);
        var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
        this.loindetialsadmin = showedlogindetails.response;
        // console.log(this.loindetialsadmin)
      } else {
        localStorage.removeItem("adminLogindetails");
        localStorage.removeItem("getAdminExpriyDate");
      }
    }
  }
  public adminURL = this.AzureApiService.configs.ApiEndpoint + "asset/";
  public adminURLAPI = this.AzureApiService.configs.ApiEndpoint + "api";
  public AccessURLadminPartner = this.AzureApiService.configs.PartnerLoginUrl;
  // Read
  loginadminservice(logingetadmin) {
    return this.http.post(
      this.adminURLAPI + "/admin/unameValidate",
      logingetadmin
    );
  }

  getPartnerlist() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI + "/partner/showAll/" + this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getpendingpartner() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/Admin/partner/showAllpendingparter/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getParkingDetailspartner() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(this.adminURLAPI + "/Admin/trinqo/getParkingSpace", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  getparkingdetailsadminID(passedIDparking) {
    // for local storage
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI + "/common/getoneParkingSpace/" + passedIDparking,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getpartnerdetailsadminID(passedIDpartner) {
    // for local storage
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(this.adminURLAPI + "/partner/get/" + passedIDpartner, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  customergetallregister() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(this.adminURLAPI + "/Admin/registered/User/", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
      }),
    });
  }
  adminbookeddetailsall() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getallBookingdetails/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  adminbookedall() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getallBookingdetails/" +
        this.ParkingServiceType +
        "?Booking_status=booked",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  admincancelledall() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getallBookingdetails/" +
        this.ParkingServiceType +
        "?Booking_status=cancelled",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  admincompletedall() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getallBookingdetails/" +
        this.ParkingServiceType +
        "?Booking_status=completed",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  adminnotshownall() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getallBookingdetails/" +
        this.ParkingServiceType +
        "?Booking_status=not shown",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  adminacceptPartner(partnerID, partnerdata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI + "/Admin/partner/acceptorreject/" + partnerID,
      partnerdata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  adminacceptParking(parkingID, parkingdata) {
    // /parking/acceptorreject/
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI + "/Admin/parking/acceptorreject/" + parkingID,
      parkingdata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getbookingdetailsID(passedIDbooking) {
    // for local storage
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/Common/bookingdetail/" +
        passedIDbooking +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  bookingDivertID(divertID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/DivertBooking/" +
        divertID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  confirmdivert(divertbook, viewdivertID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI +
        "/Admin/ConfirmDivertBooking/" +
        divertbook +
        "/" +
        this.ParkingServiceType,
      viewdivertID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  admingetshow(getID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(this.adminURLAPI + "/Admin/show/" + getID, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
      }),
    });
  }
  adminaccupdate(accountdata, accID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI + "/Admin/updateadmin/" + accID,
      accountdata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  BandTableletter(IDband, passdataBand) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/partner/insertband/" + IDband,
      passdataBand,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  priceTableletter(IDprice, passdataprice) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/partner/insertprice/" + IDprice,
      passdataprice,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  priceTableletterupdate(IDpriceupdate, passPartnerID, passdatapriceupdate) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI +
        "/Common/editprice/" +
        IDpriceupdate +
        "/" +
        passPartnerID,
      passdatapriceupdate,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getPricingeditupdate(IDprice, passPartnerID, passdataprice) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/partner/pricetable/" + IDprice + "/" + passPartnerID,
      passdataprice,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getbandshowpriceedit(IDBand, passPartnerID, passdataBand) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/partner/showbandval/" + IDBand + "/" + passPartnerID,
      passdataBand,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getPricingshowpriceedit(IDprice, passPartnerID, passdataprice) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI +
        "/partner/showpriceval/" +
        IDprice +
        "/" +
        passPartnerID,
      passdataprice,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  bandtableUpdate(IDbandupdate, passPartnerID, passdatabandupdate) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI +
        "/Common/editband/" +
        IDbandupdate +
        "/" +
        passPartnerID,
      passdatabandupdate,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  deletebandtable(passid, passPartnerID, removevalue) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
      body: removevalue,
    };
    return this.http.delete(
      this.adminURLAPI + "/Common/deleteband/" + passid + "/" + passPartnerID,
      options
    );
  }
  deletepricetable(passidprice, passPartnerID, removevalueprice) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
      body: removevalueprice,
    };
    return this.http.delete(
      this.adminURLAPI +
        "/Common/deleteprice/" +
        passidprice +
        "/" +
        passPartnerID,
      options
    );
  }
  getpartnerfeedback(partnerid) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/partner/booking/bookingdetailsfeedback/" +
        partnerid +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  adminrevenue(passeddata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/Revenuechart/" + this.ParkingServiceType,
      passeddata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  ratingaverage(passeddata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/partner/booking/Ratingfeedback/" +
        passeddata +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // download csv file
  financeMonthlyreview(PassedSearchdata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/csv/" + this.ParkingServiceType,
      PassedSearchdata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  bookedDashCount() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/Admin/booking/getBookingdetailsbookedcount/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  adminSendOtppass(passadminID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/otpsend/" + passadminID,
      "",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  adminchangePAss(passadminID, PassData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/passwordchange/" + passadminID,
      PassData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  payoutstatusAdmin(PayoutData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/payoutstatus/" + this.ParkingServiceType,
      PayoutData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getpendingCount() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/Admin/totalcount/partner/" +
        this.ParkingServiceType +
        "?partner_status=pending/",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getnotshownCount() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/admin/booking/getBookingdetailscount/" +
        this.ParkingServiceType +
        "?Booking_status=not shown",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  updatPersonaldetails(GetID, Getdetails) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/Admin/updatepersonaldetail/" + GetID,
      Getdetails,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  passcommissionrate(GetID, passrate) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(
      this.adminURLAPI + "/admin/commisionrate/" + GetID,
      passrate,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getPArtnerandUsercount() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/Admin/countofuserandpartner/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getParkingspacePartnerID(partnerID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI + "/partner/trinqo/partner/parking_space/" + partnerID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getBookingPartner(partnerID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/partner/trinqo/allparkingspacebookings/" +
        partnerID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  loopingRatingUser() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.post(this.adminURLAPI + "/Admin/rating", "", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  getCommissionrateshow(partnerID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI +
        "/admin/showcommissionrate/" +
        partnerID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  showBankdetaisPartner(partnerID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    // end
    return this.http.get(
      this.adminURLAPI + "/partner/showacct_info/" + partnerID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  partnerBookingnotify() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/partner/bookingdetailofpartner/1/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getBookingchecked(idbooking) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/AdminNotificationBKchecked/" +
        idbooking +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  partneracceptrejectnotify() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/partner/partneracceptrejinfo/1/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  parkingacceptrejectnotify() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/partner/parkingacceptrejinfo/1/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  parkingSpacechecked(ParkingID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/AdminNotificationPKchecked/" +
        ParkingID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  partnerSpacechecked(PartnerID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/AdminNotificationPDchecked/" +
        PartnerID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  commisionrateCommon(commondata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/setdefaultcommisionrate",
      commondata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  bookingFeeCommon(commondata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/setdefaultbookingrate",
      commondata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  lastminbookingFeeCommon(Getlastminbookingfeebindofid, commondata) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI +
        "/Admin/updatebookingfee/" +
        Getlastminbookingfeebindofid,
      commondata,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  GetCommissionCommon() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/getdefaultcommissionfee/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  GetbookingfeeCommon(passBooking) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/getdefaultbookingfee/",
      passBooking,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  GetlastminbookingfeeCommon() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(this.adminURLAPI + "/Admin/getbookingfee/", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
      }),
    });
  }

  optinalExtrapost(passOpData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/insertnewopex/" + this.ParkingServiceType,
      passOpData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  GetoptinalExtraData() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI + "/Admin/getallopex/" + this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  EditoptinalExtraData(PassID, DataOP) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI +
        "/Admin/updateopex/" +
        PassID +
        "/" +
        this.ParkingServiceType,
      DataOP,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  deleteOptinalExtravalue(passid) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
    };
    return this.http.delete(
      this.adminURLAPI + "/Admin/deleteopex/" + passid,
      options
    );
  }
  GetcancellationfeeCommon(passCancel) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/getdefaultcancelfee/",
      passCancel,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  postCancellationrate(cancelData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/setdefaultcancelrate/",
      cancelData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  diverthistoryget(parkingID) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI +
        "/Admin/diversionhistory/" +
        parkingID +
        "/" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getCalenderINoutData(partner_id, datacalender) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/partner/bookingdetailsorcountformonth/" + partner_id,
      datacalender,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getairportList(passAirportDetails) {
    return this.http.post(
      this.adminURLAPI + "/Common/airportlist/details",
      passAirportDetails
    );
  }
  // coupon code started
  couponCodeCreate(passedObject) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/createcoupon/" + this.ParkingServiceType,
      passedObject,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  getAllCouponsetting() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI + "/Admin/allcoupon/" + this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  deleteCouponcode(passid) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
    };
    return this.http.delete(
      this.adminURLAPI + "/Admin/deletecoupon/" + passid,
      options
    );
  }
  couponCodeUpdate(CouponID, passedObject) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI +
        "/Admin/updatecoupon/" +
        CouponID +
        "/" +
        this.ParkingServiceType,
      passedObject,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  // coupon code end
  // document for Partner
  documentCreate(passedObject) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/addDocumentnames/",
      passedObject,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  documentgetAdmin(passedData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/getdocumentName",
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  UpdatedocumentAdmin(GEtID, passedData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.put(
      this.adminURLAPI + "/Admin/updateDocumentnames/" + GEtID,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  // end document for partner
  // start custom slider
  getCustomSlider() {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.get(
      this.adminURLAPI + "/Admin/getSlideImages/" + this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  deleteCustomSlider(passid) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
      body: passid,
    };
    return this.http.delete(this.adminURLAPI + "/Admin/deleteslides/", options);
  }
  createCustomSlider(passedData) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    return this.http.post(
      this.adminURLAPI + "/Admin/addslideimages",
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  deleteDocumentParking(passid) {
    // var getlogindetails = localStorage.getItem("adminLogindetails")
    var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
    this.loindetialsadmin = showedlogindetails.response;
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
      // body: passid,
    };
    return this.http.delete(
      this.adminURLAPI + "/Admin/deleteDocuments/" + passid,
      options
    );
  }
  // end custom slider
  completedButtonclick(editdata, partnerID, test) {
    return this.http.put(
      this.adminURLAPI +
        "/partner/completedbooking/" +
        editdata +
        "/" +
        partnerID +
        "/" +
        this.ParkingServiceType,
      test,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  notshowButtonclick(editdata, partnerID, test) {
    return this.http.put(
      this.adminURLAPI +
        "/partner/notshownbooking/" +
        editdata +
        "/" +
        partnerID +
        "/" +
        this.ParkingServiceType,
      test,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  // added roles
  GetallRolesuser(partnerID) {
    return this.http.get(
      this.adminURLAPI + "/partner/getAllMultiUsers/" + partnerID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end roles
  // enable / disble
  partnerBandEnableDisbale(partnerID, passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/enableordisablepriceorband/" + partnerID,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end enable /disable
  testMailPartnerSend(partnerID, GetTypeName) {
    return this.http.get(
      this.adminURLAPI +
        "/partner/sendTestMail/" +
        partnerID +
        "/" +
        GetTypeName,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  partnerTradingName(partnerID, passedData) {
    return this.http.post(
      this.adminURLAPI + "/Admin/updateTradingName/" + partnerID,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  mobileVerifyAdmintoPartner(partnerID) {
    return this.http.post(
      this.adminURLAPI + "/Admin/contactnumberVerification/" + partnerID,
      "",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  invoiceGenerated(ID, userID, storeUsers) {
    return this.http.post(
      this.adminURLAPI +
        "/User/generate/" +
        ID +
        "/" +
        userID +
        "/" +
        this.ParkingServiceType +
        "?for=" +
        storeUsers,
      "",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // start refferal
  getAllreferral() {
    return this.http.get(this.adminURLAPI + "/Admin/allRefferal/", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  getSinglereferral(getID: any) {
    return this.http.get(this.adminURLAPI + "/Admin/getRefferal/" + getID, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  updateRefferal(ID: any, refferalData: any) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updateRefferal/" + ID,
      refferalData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getStaticCoupon(CouponValue) {
    return this.http.get(
      this.adminURLAPI +
        "/Admin/StaticDynamicCoupon/" +
        this.ParkingServiceType +
        "?type=" +
        CouponValue,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  customermoreInfo(custID) {
    return this.http.get(this.adminURLAPI + "/user/show/" + custID, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  customerBookingGet(custID) {
    return this.http.get(
      this.adminURLAPI + "/Common/booking/getBookingdetails/" + custID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  customerGetreviews(custID) {
    return this.http.get(
      this.adminURLAPI + "/User/showUserfeedback/" + custID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // points euqi start
  getPOintsRefer() {
    return this.http.get(this.adminURLAPI + "/Admin/getPointsEq/1", {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  UpdatePointEuqi(ID, refferalData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updatePointsEq/" + ID,
      refferalData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end points
  getreferredByUserData(UserRef: any) {
    return this.http.get(
      this.adminURLAPI + "/Admin/dynamiccouponinfo/" + UserRef,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end refferal
  // edit booking
  updateBookingmodel(ID, userID, userEditDetails) {
    return this.http.post(
      this.adminURLAPI + "/User/updatebooking/" + ID + "/" + userID,
      userEditDetails,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  changeSchedulerefunddata(IDGEt, UserID, datapAsss) {
    return this.http.post(
      this.adminURLAPI +
        "/User/changeschedule/getprice/" +
        IDGEt +
        "/" +
        UserID,
      datapAsss,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
    //
  }
  editWithoutPriceChange(IDGEt: any, datapAsss) {
    return this.http.put(
      this.adminURLAPI + "/Admin/editbookingdateandtime/" + IDGEt,
      datapAsss,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  passReschedlue(IDGEt, UserID, datapAsss) {
    return this.http.post(
      this.adminURLAPI +
        "/User/partialrefund/" +
        IDGEt +
        "/" +
        UserID +
        "/" +
        this.ParkingServiceType,
      datapAsss,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  bookingCancel(BookinggetID, userID, passedData) {
    return this.http.post(
      this.adminURLAPI +
        "/User/fullrefund/" +
        BookinggetID +
        "/" +
        userID +
        "/" +
        this.ParkingServiceType,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  bookingFullCancel(BookinggetID, userID, passedData, storeQuery: any) {
    return this.http.post(
      this.adminURLAPI +
        "/User/fullrefund/" +
        BookinggetID +
        "/" +
        userID +
        "/" +
        this.ParkingServiceType +
        "?refundtype=full&FullrefundorFullCancel=" +
        storeQuery,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  activeMailPartner(passedData) {
    return this.http.post(
      this.adminURLAPI + "/partner/adminactivationpartner",
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  blockedUnblockedCalendar(ps_id, passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/BlockandUnblock/" + ps_id,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getBlockUnblockDates(ps_id: any) {
    return this.http.get(this.adminURLAPI + "/Admin/allBlockedDates/" + ps_id, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
  }
  cutoffHrsUpdate(pt_id, passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updateCutOffHrs/" + pt_id,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  updateOperationhrsparking(pt_id, passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updateOperationalHrs/" + pt_id,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  sendSmsParking(pt_id, passedData) {
    return this.http.post(
      this.adminURLAPI +
        "/Admin/sendSms/" +
        pt_id +
        "/" +
        this.ParkingServiceType,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end edit booking
  // payout download
  partnerPayoutDownload(pt_id: any, passedData: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/PayoutDetailCsvPartner/" + pt_id,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end download

  // start trust pilot
  getTrstPilotData(getParams: any) {
    return this.http.get(
      this.adminURLAPI + "/Admin/showReviewTime/" + getParams,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  updatetimeTrustPilot(getParams: any, passedData: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/updatetime/" + getParams,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  UpdatePartnerEnable(pt_id: any, passData: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/partneReviewmailStatus/" + pt_id,
      passData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  OneUserTrustPilotreview(bookingID: any, userID: any, getParams: any) {
    return this.http.post(
      this.adminURLAPI +
        "/Admin/sendReviewmailToneuser/" +
        bookingID +
        "/" +
        userID +
        "?emailType=" +
        getParams,
      "",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  overWritebookingStatusBooking(bookingID: any) {
    return this.http.put(
      this.adminURLAPI + "/Admin/ToBooked/" + bookingID,
      "",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  } // end trust pilot

  // new dashboard
  admindashboardnew() {
    return this.http.get(
      this.adminURLAPI + "/Admin/adminDashboardTotalNumOfBookingAndSalesprice",
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  chartallviews(valuesID: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/AdminDashboardchart",
      valuesID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  overwriteEmailContentParking(ps_id: any) {
    return this.http.get(
      this.adminURLAPI + "/Admin/getOverwriteContent/" + ps_id,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  overwriteUpdateContentParking(ps_id: any, valuesID: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/overwriteConfirmationMail/" + ps_id,
      valuesID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  discountpricing(ps_id: any, valuesID: any) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updatePricingType/" + ps_id,
      valuesID,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  smsMarketing(getValuez: any) {
    return this.http.post(
      this.adminURLAPI + "/admin/userCouponBulkSms",
      getValuez,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // start added review
  addreviewPartnerByadmin(getValuez: any) {
    return this.http.post(
      this.adminURLAPI + "/Admin/addAdminFeedbackForPartners",
      getValuez,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  getCustomPartnerReview(pd_id: any) {
    return this.http.get(
      this.adminURLAPI +
        "/Admin/getAllAdminFeedbackForPartners?pd_id=" +
        pd_id +
        "&serviceName=" +
        this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  deleteCustomReview(passid) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: this.loindetialsadmin.token,
      }),
      // body: "",
    };
    return this.http.delete(
      this.adminURLAPI + "/Admin/deleteAdminFeedbackForPartners/" + passid,
      options
    );
  }
  updatereviewPartnerByadmin(pass_id: any, getValuez: any) {
    return this.http.put(
      this.adminURLAPI +
        "/Admin/updateAdminFeedbackForPartners/" +
        pass_id +
        "&serviceName=" +
        this.ParkingServiceType,
      getValuez,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }
  // end added review
  // start default band table
  getPricingTableDefalut(PartnerID: any) {
    return this.http.get(
      this.adminURLAPI + "/partner/getdefaultband/" + PartnerID,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  updatePricingTableDefalut(PartnerID: any, bandName: any) {
    return this.http.put(
      this.adminURLAPI +
        "/partner/updateorinsertdefaultband/" +
        PartnerID +
        "?defaultband=" +
        bandName,
      "",
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }
  // end defalt band table

  markasDivertedCheckbox(requestBody: any) {
    return this.http.put(
      this.adminURLAPI + "/Admin/MarkasDiverted",
      requestBody,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  terminatedPartner(PartnerID: any, statusData: any, noOfDaysEnable: any) {
    return this.http.put(
      this.adminURLAPI +
        "/Admin/ClosePartner/" +
        PartnerID +
        "?status=" +
        statusData,
      noOfDaysEnable,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  parkingSpaceDiverted(PartnerID: any) {
    return this.http.get(
      this.adminURLAPI +
        "/partner/trinqo/allparkingspacebookings/" +
        PartnerID +
        "/" +
        this.ParkingServiceType +
        "?Booking_status=Diverted",
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  diversionReminder() {
    return this.http.get(
      this.adminURLAPI + "/Admin/DiversionRemainder/" + this.ParkingServiceType,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  closingPartnerBookinglist() {
    return this.http.put(
      this.adminURLAPI +
        "/Admin/getClosingPartnerBookinglistForNextfewDays/" +
        this.ParkingServiceType,
      null,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  updatebookingperday(partnerID, passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/updateNumberofBookingsPerday/" + partnerID,
      passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  updatepriority( passedData) {
    return this.http.put(
      this.adminURLAPI + "/Admin/UpdatePriorityLabelAndcolor/",  passedData,
      {
        headers: new HttpHeaders({
          Authorization: this.loindetialsadmin.token,
          "Content-Type": "application/json",
        }),
      }
    );
  }

  bookingFormApi(ID, postDataBooking, getAffiliatedID) {
    // var getlogindetails = localStorage.getItem("customerLogindetails")
    if (this.passAdminDetailsLocalStorage) {
      var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
      this.loindetialsadmin = showedlogindetails.response;
      return this.http.post(
        this.adminURLAPI +
          "/User/booknow/checkout/" +
          ID +
          "/" +
          this.ParkingServiceType +
          getAffiliatedID,
        postDataBooking,
        {
          headers: new HttpHeaders({
            Authorization: this.loindetialsadmin.token,
            "Content-Type": "application/json",
          }),
        }
      );
    }
  }

  addUserbookingrelated(postedUser, addedAffiliatedID) {
    return this.http.post(
      this.adminURLAPI +
        "/User/add/" +
        this.ParkingServiceType +
        addedAffiliatedID,
      postedUser
    );
  }

  airportListget(Getvalue) {
    return this.http.post(
      this.adminURLAPI + "/Common/airportlist/details",
      Getvalue
    );
  }

  comparesearchhome(passedSearch: any) {
    return this.http.get(
      this.adminURLAPI +
        "/User/trinqo/getallacceptedParkingSpace/" +
        this.ParkingServiceType +
        "?airport=" +
        passedSearch
    );
  }

  getParkingSpaceID(IDParking: any) {
    return this.http.get(
      this.adminURLAPI + "/Common/getoneParkingSpace/" + IDParking
    );
  }

  userprofileview(getID: any) {
    // if (this.passUserDetailsCookiesData) {
    //   var showedlogindetails = JSON.parse(this.passUserDetailsCookiesData);
    //   this.loindetialscustomer = showedlogindetails.response;
    return this.http.get(this.adminURLAPI + "/user/show/" + getID, {
      headers: new HttpHeaders({
        Authorization: this.loindetialsadmin.token,
        "Content-Type": "application/json",
      }),
    });
    // }
  }

  forgetextraoptional() {
    return this.http.get(
      this.adminURLAPI + "/Admin/getallopex/" + this.ParkingServiceType
    );
  }

  getCouponValid(getID, partnerID, passValue) {
    if (this.passAdminDetailsLocalStorage) {
      var showedlogindetails = JSON.parse(this.passAdminDetailsLocalStorage);
      this.loindetialsadmin = showedlogindetails.response;
      return this.http.post(
        this.adminURLAPI +
          "/Admin/couponconstraint/" +
          getID +
          "/" +
          this.ParkingServiceType +
          "/" +
          partnerID,
        passValue,
        {
          headers: new HttpHeaders({
            Authorization: this.loindetialsadmin.token,
            "Content-Type": "application/json",
          }),
        }
      );
    }
  }

  couponcodewithoutLogin(partnerId, passCoupon) {
    return this.http.post(
      this.adminURLAPI +
        "/Admin/couponconstraintfornonuser/" +
        this.ParkingServiceType +
        "/" +
        partnerId,
      passCoupon
    );
  }

  WalletPointLoaded(userId, passAmount) {
    return this.http.post(
      this.adminURLAPI +
        "/User/walletconstraint/" +
        userId +
        "/" +
        this.ParkingServiceType,
      passAmount,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: this.loindetialsadmin.token,
        }),
      }
    );
  }

  Foremailvalidaiton(getEmail) {
    return this.http.get(
      this.adminURLAPI + "/User/emailValidation/User?email=" + getEmail
    );
  }
}
